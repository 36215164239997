import { Error } from '@/components/Error';
import { Header } from '@/components/Header';
import { Meta } from '@/layout/Meta';
import { Main } from '@/templates/Main';

export default function NotFound() {
  return (
    <>
      <Main meta={<Meta title="Page Not Found" />}>
        <Header showOnlyLogo={true}>
          <Error
            errorCode={404}
            errorName={'Page not found.'}
            errorMessage={
              'Sorry, we couldn’t find the page you’re looking for.'
            }
          />
        </Header>
      </Main>
    </>
  );
}

import { useClerk } from '@clerk/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';

const Error = ({
  errorCode,
  errorName,
  errorMessage,
}: {
  errorCode: number;
  errorName: string;
  errorMessage: string;
}) => {
  const router = useRouter();
  const { session } = useClerk();

  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link href="/dashboard" passHref>
            <img
              className="h-40 w-auto cursor-pointer"
              src={`${router.basePath}/assets/images/adaptmx_logo.png`}
              alt="logo"
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
              {errorCode} error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {errorName}
            </h1>
            <p className="mt-2 text-base text-gray-500">{errorMessage}</p>
            {session && (
              <div className="mt-6">
                <Link href="/dashboard" passHref>
                  <div className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                    Go back home<span aria-hidden="true"> &rarr;</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export { Error };
